import Axios from "@/plugins/Axios";
import store from "@/store";
import Turma from "@/Models/Turma";

export class TurmasService {
  async syncAll() {
    const response = await Axios().get("turmas");
    const turmas = response.data.map((turma) => new Turma(turma));
    // store.commit("Turmas/setTurmas", turmas);
    return turmas;
  }

  async syncAllPaginate(pageNumber, query) {
    const response = await Axios().get(`turmas/listall/paginate?page=${pageNumber}&query=${query}`);
    return response.data;
  }

  async syncAllGeral() {
    const response = await Axios().get("turmas/retirarFiltroFranquia");
    const turmas = response.data.map((turma) => new Turma(turma));
    // store.commit("Turmas/setTurmas", turmas);
    return turmas;
  }

  async visualizarTurma(turma) {
    const response = await Axios().get(`turmas/visualizar/${turma}`);
    const novaTurma = response.data;
    store.commit("Turmas/visualizarTurma", novaTurma);
    return novaTurma;
  }

  async criarTurma(turma) {
    const response = await Axios().post("turmas", turma);
    const novaTurma = response.data;
    store.commit("Turmas/adicionarTurma", novaTurma);
    return novaTurma;
  }

  async listarTurmasFranquiaSelect(form) {
    const response = await Axios().post("turmas/listar-turmas-franquia-select", form);
    const turmas = response.data.map((turma) => new Turma(turma));
    return turmas;
  }

  async atualizarTurma(turma) {
    const response = await Axios().put(`turmas/${turma.id}`, turma);
    const turmaAtualizada = response.data;
    store.commit("Turmas/atualizarTurma", turmaAtualizada);
    return turmaAtualizada;
  }

  async deletarTurma(turma) {
    const response = await Axios().delete(`turmas/${turma.id}`);
    const turmaDeletada = response.data;
    store.commit("Turmas/deletarTurma", turma.id);
    return turmaDeletada;
  }

  async recuperarTurma(turma) {
    return this.atualizarTurma({ ...turma, deleted_at: null });
  }

  async pegarMatriculas(turma) {
    const response = await Axios().get(`turmas/pegar-matriculas/${turma}`);
    const matriculas = response.data;
    return matriculas;
  }

  async listTurmasEspecias() {
    const response = await Axios().get("turmas/turmas-especias/inclusiva");
    const turmas = response.data.map((turma) => new Turma(turma));
    // store.commit("Turmas/setTurmas", turmas);
    return turmas;
  }

  // Metricas da turma
  async pegarNotas(turmas) {
    const response = await Axios().post(`turmas/notas/metricas`, turmas);
    return response;
  }

  async pegarMedias(turmas) {
    const response = await Axios().post(`turmas/medias/metricas`, turmas);
    return response;
  }

  async pegarMelhoresAlunos(turmas) {
    const response = await Axios().post(`turmas/melhores-alunos/metricas`, turmas);
    return response;
  }

  async pegarMelhoresAlunosDaGestao(turmas, gestao) {
    const response = await Axios().post(`turmas/melhores-alunos/metricas-gestao`, turmas, gestao);
    return response;
  }

  async pegarAcompanhamentos(turmas) {
    const response = await Axios().post(`turmas/acompanhamentos/metricas`, turmas);
    return response;
  }

  async mostrarTurma(turma_id) {
    const response = await Axios().get(`turmas/visualizar/${turma_id}/metricas`);
    return response;
  }

  async qtd() {
    const response = await Axios().get("turmas/qtd");
    return response.data;
  }

  async taxaOcupacao() {
    const response = await Axios().get("turmas/taxa-ocupacao");
    return response.data;
  }

  async pegarMelhoresAlunosDaGestaoInfantis(turmas, gestao) {
    const response = await Axios().post(
      `turmas/melhores-alunos/metricas-gestao-infantis`,
      turmas,
      gestao
    );
    return response;
  }
}
export default new TurmasService();
